import { ReactElement } from 'react';

import ErrorContent from '@/components/interfaces/Errors/ErrorContent';
import MainLayout from '@/components/layouts/MainLayout';

const Custom404 = () => {
  return <ErrorContent />;
};
Custom404.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout backgroundColor='dark'>{page}</MainLayout>;
};

export default Custom404;
